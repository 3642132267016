import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PaymentItem from "./PyamentItem.jsx";
import Loading from "./UI/LoadingAnim.jsx";
import axios from "axios";

const PaymentsList = () => {
  const { isLoading, sortedPayments } = useSelector((state) => state.payment);
  // const getPayments = async() => {
  //   // const resp = await axios.get("https://api.steam-rent.com/getPayments?limit=100").then(resp => setSortedPayments(resp.data))
  //   const resp = await axios.get(`http://localhost:8080/getPayments?limit=${limim}`).then(resp => setSortedPayments(resp.data))
  // }

  // const [sortedPayments,setSortedPayments] = useState(null)
  // const [limim,setLimit] = useState(100)

  
  // useEffect(() => {
  //   getPayments()
  // },[])


  if (!sortedPayments) {
    return <Loading />;
  }

  return sortedPayments.map((item, i) => (
    <>
      <PaymentItem
        i={i}
        sortedPayments={sortedPayments}
        key={item._id}
        item={item}
      />
    </>
  ));
};

export default React.memo(PaymentsList);
