import React, { useEffect, useMemo, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import axios from "axios";
import Loader2 from "../UI/Loader2";

const Pie = () => {
  const [incomeData, setIncomeData] = useState([{}]);
  const [incomeDataGames, setIncomeDataGames] = useState([{}]);
  const [incomeDataGG, setIncomeDataGG] = useState([{}]);
  const [timeLine, setTimeLine] = useState(30);
  const [otherSide,setOtherSide] = useState(false)
  const [denuvaData,setDenuvaData] = useState(null)
  const [otherSideLoading,setOtheSideLoading] = useState(false)
  const [gamesStat,setGamesStat] = useState(null)
  const [usersByCounties,setUsersByCounties] = useState(null)
  const [usersSide,setUsersSide] = useState(false)

  const getData = async () => {
    try {
      const data = await axios
        .post("https://api.steam-rent.com/getincomestatistics4pie", {
          timeLine,
        })
        .then((resp) => resp.data);
        console.log(data)
      setIncomeData(data.all);
      setIncomeDataGames(data.games);
    } catch (e) {
      console.log(e);
    }
  };


  const getDenuvaData = async() => {
    console.log(incomeData)
    if(denuvaData || otherSideLoading){
      denuvaData && setOtherSide(!otherSide)

      return
    }
    setOtheSideLoading(true)
    setOtherSide(!otherSide)

  
    
    
    const data = await axios.post('https://api.steam-rent.com/admin/getDenuvaData',{timeout: 1600000}).then(resp => resp.data)
    // console.log(data) 
    setOtheSideLoading(false)
    setDenuvaData([data.withDenuvaForStat,data.withOutDenuvaForStat])

  }

  const getGamesStatData = async() => {
    const data = await axios.post('http://localhost:8080/admin/getGamesStat',{timeout: 1600000}).then(resp => resp.data)
    console.log(data)
    setGamesStat(data)
    
  }

  const getUsersByCounties = async() => {
    const data = await axios.post('https://api.steam-rent.com/user/getUserByC',{pass:'12'}).then(resp => resp.data)
    // const data = await axios.post('http://localhost:8080/user/getUserByC',{pass:'12'}).then(resp => resp.data)
    setUsersByCounties(data)
    console.log(data)
  }


  useEffect(() => {
    !usersByCounties && getUsersByCounties()
  },[usersSide])


  
  useEffect(() => {
    getData();
    // getUsersByCounties()
    // getGamesStatData()
  }, [timeLine]);

  return (
    <div className="statistics__pie__container">
      <div  className="statistics__pie">
      {!otherSide ? 
              <ResponsivePie
              data={incomeData}
              arcLabelsTextColor={"#fff"}
              margin={{ top: 43, right: 43, bottom: 43, left: 43 }}
              // colors={{ scheme: "purpleRed_green" }}
              // colors={{ scheme: "red_blue" }}
              // colors={{ scheme: 'set3' }}
              colors={['#0C1446','#b2182b','#00AEAE','#00AEAE','#50C878','#f08307','rgb(2, 119, 228)']}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={2}
              borderColor={{ from: "color", modifiers: [["brighter", 0.5]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#E5E5CB"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
            />
            :
            denuvaData ? <ResponsivePie
            data={denuvaData}
            arcLabelsTextColor={"#fff"}
            margin={{ top: 43, right: 43, bottom: 43, left: 43 }}
            //   colors={{ scheme: "purpleRed_green" }}
            colors={{ scheme: "red_blue" }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={2}
            borderColor={{ from: "color", modifiers: [["brighter", 0.7]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#E5E5CB"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
          />
          :
          <Loader2/>
          }

        <div
          onClick={() => {getDenuvaData()}}
          className="timeline"
        >
          {otherSideLoading ? <p>Loading</p> : !otherSide ? <p>All</p> : <p>Denuva</p>}
        </div>
      </div>
      <div className="statistics__pie">
    {usersSide && usersByCounties ?  <ResponsivePie
          data={usersByCounties}
          arcLabelsTextColor={"#fff"}
          margin={{ top: 43, right: 43, bottom: 43, left: 43 }}
          //   colors={{ scheme: "purpleRed_green" }}
          colors={{ scheme: "spectral" }}
          // colors={{ scheme: "red_blue" }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={2}
          borderColor={{ from: "color", modifiers: [["brighter", 0.7]] }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#E5E5CB"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
        />
        :
        <ResponsivePie
          data={incomeDataGames}
          arcLabelsTextColor={"#fff"}
          margin={{ top: 43, right: 43, bottom: 43, left: 43 }}
          //   colors={{ scheme: "purpleRed_green" }}
          colors={{ scheme: "red_blue" }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={2}
          borderColor={{ from: "color", modifiers: [["brighter", 0.7]] }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#E5E5CB"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
        />}

        {/* {gamesStat && 
          <ResponsivePie
            data={gamesStat}
            arcLabelsTextColor={"#fff"}
            margin={{ top: 43, right: 43, bottom: 43, left: 43 }}
            //   colors={{ scheme: "purpleRed_green" }}
            colors={colors} 
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={2}
            borderColor={{ from: "color", modifiers: [["brighter", 0.7]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#E5E5CB"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
          /> 
          } */}


        <div
          onClick={() => (timeLine == 30 ? setTimeLine(7) : setTimeLine(30))}
          className="timeline"
        >
          {timeLine == 30 ? <p>Month</p> : <p>Week</p>}
        </div>
        <div
          onClick={() => setUsersSide(!usersSide)}
          className="timeline"
        >
          {!usersSide ? <p>o/f</p> : <p>RUsers</p>}
        </div>
      </div>
    </div>
  );
}

export default Pie;
